import { Pathnames } from 'next-intl/routing';
import { IConfig } from './lib/models/graphs/config';

export type TLocale = 'pt' | 'en';

export const locales = ['pt', 'en'] as TLocale[];

type TPathnames = {
  [key: string]: { [locale in TLocale]: string };
};

export const pathnames: TPathnames = {
  '/': {
    pt: '/',
    en: '/',
  },
  '/painel': {
    pt: '/painel',
    en: '/dashboard',
  },
  '/painel/configuracoes': {
    pt: '/painel/configuracoes',
    en: '/dashboard/settings',
  },
  '/painel/gestao-de-contributos': {
    pt: '/painel/gestao-de-contributos',
    en: '/dashboard/contributions-management',
  },
  '/painel/gestao-de-contributos/novo': {
    pt: '/painel/gestao-de-contributos/novo',
    en: '/dashboard/contributions-management/new',
  },
  '/painel/gestao-de-contributos/editar': {
    pt: '/painel/gestao-de-contributos/editar',
    en: '/dashboard/contributions-management/edit',
  },
  '/painel/gestao-de-contributos/editar/[contributionId]': {
    pt: '/painel/gestao-de-contributos/editar/[contributionId]',
    en: '/dashboard/contributions-management/edit/[contributionId]',
  },
  '/painel/company-nps': {
    pt: '/painel/company-nps',
    en: '/dashboard/company-nps',
  },
  '/painel/company-kpis': {
    pt: '/painel/company-kpis',
    en: '/dashboard/company-kpis',
  },
  '/painel/company-previsoes': {
    pt: '/painel/company-previsoes',
    en: '/dashboard/company-predictions',
  },
  '/contributos': {
    pt: '/contributos',
    en: '/contributions',
  },
  '/nps': {
    pt: '/nps',
    en: '/nps',
  },
  '/opinioes': {
    pt: '/opinioes',
    en: '/opinions',
  },
  '/opinioes/[opinionSlug]': {
    pt: '/opinioes/[opinionSlug]',
    en: '/opinions/[opinionSlug]',
  },
  '/opinioes/autor/[name]': {
    pt: '/opinioes/autor/[name]',
    en: '/opinions/author/[name]',
  },
  '/previsoes': {
    pt: '/previsoes',
    en: '/forecasts',
  },
  '/bem-vindo': {
    pt: '/bem-vindo',
    en: '/welcome',
  },
  '/registar': {
    pt: '/registar',
    en: '/signup',
  },
  '/entrar': {
    pt: '/entrar',
    en: '/login',
  },
  '/politica-de-privacidade': {
    pt: '/politica-de-privacidade',
    en: '/privacy-policy',
  },
  '/termos-e-condicoes': {
    pt: '/termos-e-condicoes',
    en: '/terms-and-conditions',
  },
  '/politica-de-cookies': {
    pt: '/politica-de-cookies',
    en: '/cookie-policy',
  },
  '/sobre-nos': {
    pt: '/sobre-nos',
    en: '/about-us',
  },
  '/kpis': {
    pt: '/kpis',
    en: '/kpis',
  },
  '/kpis/atividade-turistica/consumos': {
    pt: '/kpis/atividade-turistica/consumos',
    en: '/kpis/tourist-activity/consumption',
  },
  '/kpis/atividade-turistica/consumos/consumo-medio-de-energia-por-habitante': {
    pt: '/kpis/atividade-turistica/consumos/consumo-medio-de-energia-por-habitante',
    en: '/kpis/tourist-activity/consumption/average-energy-consumption-per-inhabitant',
  },
  '/kpis/atividade-turistica/consumos/consumo-medio-de-agua-por-habitante': {
    pt: '/kpis/atividade-turistica/consumos/consumo-medio-de-agua-por-habitante',
    en: '/kpis/tourist-activity/consumption/average-water-consumption-per-inhabitant',
  },
  '/kpis/atividade-turistica/consumos/consumo-total-estimado-de-energia': {
    pt: '/kpis/atividade-turistica/consumos/consumo-total-estimado-de-energia',
    en: '/kpis/tourist-activity/consumption/estimated-total-energy-consumption',
  },
  '/kpis/atividade-turistica/consumos/consumo-total-estimado-de-agua': {
    pt: '/kpis/atividade-turistica/consumos/consumo-total-estimado-de-agua',
    en: '/kpis/tourist-activity/consumption/estimated-total-water-consumption',
  },
  '/kpis/atividade-turistica/empresas-e-negocios': {
    pt: '/kpis/atividade-turistica/empresas-e-negocios',
    en: '/kpis/tourist-activity/companies-and-businesses',
  },
  '/kpis/atividade-turistica/empresas-e-negocios/estabelecimentos-em-turismo-e-hospitalidade': {
    pt: '/kpis/atividade-turistica/empresas-e-negocios/estabelecimentos-em-turismo-e-hospitalidade',
    en: '/kpis/tourist-activity/companies-and-businesses/establishments-in-tourism-and-hospitality',
  },
  '/kpis/atividade-turistica/empresas-e-negocios/empresas-em-turismo-e-hospitalidade': {
    pt: '/kpis/atividade-turistica/empresas-e-negocios/empresas-em-turismo-e-hospitalidade',
    en: '/kpis/tourist-activity/companies-and-businesses/companies-in-tourism-and-hospitality',
  },
  '/kpis/atividade-turistica/empresas-e-negocios/empresas-recem-constituidas-em-turismo-e-hospitalidade': {
    pt: '/kpis/atividade-turistica/empresas-e-negocios/empresas-recem-constituidas-em-turismo-e-hospitalidade',
    en: '/kpis/tourist-activity/companies-and-businesses/newly-established-companies-in-tourism-and-hospitality',
  },
  '/kpis/atividade-turistica/empresas-e-negocios/volume-de-negocios-em-turismo-e-hospitalidade': {
    pt: '/kpis/atividade-turistica/empresas-e-negocios/volume-de-negocios-em-turismo-e-hospitalidade',
    en: '/kpis/tourist-activity/companies-and-businesses/business-volume-in-tourism-and-hospitality',
  },
  '/kpis/atividade-turistica/empresas-e-negocios/contribuicao-de-turismo-e-hospitalidade-para-o-volume-de-negocios-total':
    {
      pt: '/kpis/atividade-turistica/empresas-e-negocios/contribuicao-de-turismo-e-hospitalidade-para-o-volume-de-negocios-total',
      en: '/kpis/tourist-activity/companies-and-businesses/contribution-of-tourism-and-hospitality-to-total-business-volume',
    },
  '/kpis/emprego/educacao-formacao-e-carreiras': {
    pt: '/kpis/emprego/educacao-formacao-e-carreiras',
    en: '/kpis/employment/education-training-and-careers',
  },
  '/kpis/emprego/educacao-formacao-e-carreiras/diplomados-em-turismo-e-hotelaria': {
    pt: '/kpis/emprego/educacao-formacao-e-carreiras/diplomados-em-turismo-e-hotelaria',
    en: '/kpis/employment/education-training-and-careers/graduates-in-tourism-and-hospitality',
  },
  '/kpis/emprego/educacao-formacao-e-carreiras/escolaridade': {
    pt: '/kpis/emprego/educacao-formacao-e-carreiras/escolaridade',
    en: '/kpis/employment/education-training-and-careers/education',
  },
  '/kpis/emprego/educacao-formacao-e-carreiras/inscritos-em-turismo-e-hotelaria': {
    pt: '/kpis/emprego/educacao-formacao-e-carreiras/inscritos-em-turismo-e-hotelaria',
    en: '/kpis/employment/education-training-and-careers/registered-in-tourism-and-hospitality',
  },
  '/kpis/emprego/educacao-formacao-e-carreiras/progressao-na-carreira': {
    pt: '/kpis/emprego/educacao-formacao-e-carreiras/progressao-na-carreira',
    en: '/kpis/employment/education-training-and-careers/career-development',
  },
  '/kpis/emprego/emprego-e-sociedade': {
    pt: '/kpis/emprego/emprego-e-sociedade',
    en: '/kpis/employment/employment-and-society',
  },
  '/kpis/emprego/emprego-e-sociedade/baixas-na-jornada-de-trabalho': {
    pt: '/kpis/emprego/emprego-e-sociedade/baixas-na-jornada-de-trabalho',
    en: '/kpis/employment/employment-and-society/absences-in-the-workday',
  },
  '/kpis/emprego/emprego-e-sociedade/despesas-sociais': {
    pt: '/kpis/emprego/emprego-e-sociedade/despesas-sociais',
    en: '/kpis/employment/employment-and-society/social-expenses',
  },
  '/kpis/emprego/emprego-e-sociedade/funcionarios-de-baixa': {
    pt: '/kpis/emprego/emprego-e-sociedade/funcionarios-de-baixa',
    en: '/kpis/employment/employment-and-society/employees-on-sick-leave',
  },
  '/kpis/emprego/emprego-e-sociedade/funcionarios-em-licenca': {
    pt: '/kpis/emprego/emprego-e-sociedade/funcionarios-em-licenca',
    en: '/kpis/employment/employment-and-society/employees-on-leave',
  },
  '/kpis/emprego/emprego-e-sociedade/horas-de-trabalho': {
    pt: '/kpis/emprego/emprego-e-sociedade/horas-de-trabalho',
    en: '/kpis/employment/employment-and-society/work-hours',
  },
  '/kpis/emprego/emprego-e-sociedade/dependencia': {
    pt: '/kpis/emprego/emprego-e-sociedade/dependencia',
    en: '/kpis/employment/employment-and-society/dependency',
  },
  '/kpis/emprego/emprego-e-sociedade/estabilidade': {
    pt: '/kpis/emprego/emprego-e-sociedade/estabilidade',
    en: '/kpis/employment/employment-and-society/stability',
  },
  '/kpis/emprego/emprego-e-sociedade/antiguidade-do-vinculo-laboral': {
    pt: '/kpis/emprego/emprego-e-sociedade/antiguidade-do-vinculo-laboral',
    en: '/kpis/employment/employment-and-society/age-of-employment-relationship',
  },
  '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade': {
    pt: '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade',
    en: '/kpis/employment/employment-and-society-in-tourism-and-hospitality',
  },
  '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade/antiguidade-do-vinculo-laboral-em-turismo-e-hospitalidade':
    {
      pt: '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade/antiguidade-do-vinculo-laboral-em-turismo-e-hospitalidade',
      en: '/kpis/employment/employment-and-society-in-tourism-and-hospitality/age-of-employment-relationship-in-tourism-and-hospitality',
    },
  '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade/estabilidade-em-turismo-e-hospitalidade': {
    pt: '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade/estabilidade-em-turismo-e-hospitalidade',
    en: '/kpis/employment/employment-and-society-in-tourism-and-hospitality/stability-in-tourism-and-hospitality',
  },
  '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade/regime-de-duracao-do-trabalho-em-turismo-e-hospitalidade':
    {
      pt: '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade/regime-de-duracao-do-trabalho-em-turismo-e-hospitalidade',
      en: '/kpis/employment/employment-and-society-in-tourism-and-hospitality/working-duration-regime-in-tourism-and-hospitality',
    },
  '/kpis/emprego/mercado-laboral': {
    pt: '/kpis/emprego/mercado-laboral',
    en: '/kpis/employment/labour-market',
  },
  '/kpis/emprego/mercado-laboral/emprego-jovem': {
    pt: '/kpis/emprego/mercado-laboral/emprego-jovem',
    en: '/kpis/employment/labour-market/youth-employment',
  },
  '/kpis/emprego/mercado-laboral/envelhecimento': {
    pt: '/kpis/emprego/mercado-laboral/envelhecimento',
    en: '/kpis/employment/labour-market/ageing',
  },
  '/kpis/emprego/mercado-laboral/inclusao': {
    pt: '/kpis/emprego/mercado-laboral/inclusao',
    en: '/kpis/employment/labour-market/inclusion',
  },
  '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade': {
    pt: '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade',
    en: '/kpis/employment/labour-market-in-tourism-and-hospitality',
  },
  '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade/escolaridade-em-turismo-e-hospitalidade': {
    pt: '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade/escolaridade-em-turismo-e-hospitalidade',
    en: '/kpis/employment/labour-market-in-tourism-and-hospitality/education-in-tourism-and-hospitality',
  },
  '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade/inclusao-em-turismo-e-hospitalidade': {
    pt: '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade/inclusao-em-turismo-e-hospitalidade',
    en: '/kpis/employment/labour-market-in-tourism-and-hospitality/inclusion-in-tourism-and-hospitality',
  },
  '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade/dependencia-em-turismo-e-hospitalidade': {
    pt: '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade/dependencia-em-turismo-e-hospitalidade',
    en: '/kpis/employment/labour-market-in-tourism-and-hospitality/dependency-in-tourism-and-hospitality',
  },
  '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade': {
    pt: '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade',
    en: '/kpis/employment/income-in-tourism-and-hospitality',
  },
  '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade/ganho-medio-por-trabalhador-em-turismo-e-hospitalidade': {
    pt: '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade/ganho-medio-por-trabalhador-em-turismo-e-hospitalidade',
    en: '/kpis/employment/income-in-tourism-and-hospitality/average-earnings-per-worker-in-tourism-and-hospitality',
  },
  '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade/remuneracao-media-por-trabalhador-em-turismo-e-hospitalidade': {
    pt: '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade/remuneracao-media-por-trabalhador-em-turismo-e-hospitalidade',
    en: '/kpis/employment/income-in-tourism-and-hospitality/average-remuneration-per-worker-in-tourism-and-hospitality',
  },
  '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade/remuneracao-media-ponderada-por-trabalhador-em-turismo-e-hospitalidade':
    {
      pt: '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade/remuneracao-media-ponderada-por-trabalhador-em-turismo-e-hospitalidade',
      en: '/kpis/employment/income-in-tourism-and-hospitality/weighted-average-remuneration-per-worker-in-tourism-and-hospitality',
    },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/rendimentos': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/rendimentos',
    en: '/kpis/employment/income-productivity-and-equity/income',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/produtividade': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/produtividade',
    en: '/kpis/employment/income-productivity-and-equity/productivity',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/equidade': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/equidade',
    en: '/kpis/employment/income-productivity-and-equity/equity',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/custo-do-trabalho-por-hora': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/custo-do-trabalho-por-hora',
    en: '/kpis/employment/income-productivity-and-equity/cost-of-labor-per-hour',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/desigualdade-salarial-hm': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/desigualdade-salarial-hm',
    en: '/kpis/employment/income-productivity-and-equity/wage-inequality-mw',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/desigualdades-na-distribuicao-de-rendimentos-s80-s20': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/desigualdades-na-distribuicao-de-rendimentos-s80-s20',
    en: '/kpis/employment/income-productivity-and-equity/inequalities-in-income-distribution-s80-s20',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/desigualdades-na-distribuicao-de-rendimentos-s90-s10': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/desigualdades-na-distribuicao-de-rendimentos-s90-s10',
    en: '/kpis/employment/income-productivity-and-equity/inequalities-in-income-distribution-s90-s10',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/desvio-padrao-da-remuneracao': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/desvio-padrao-da-remuneracao',
    en: '/kpis/employment/income-productivity-and-equity/standard-deviation-of-remuneration',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/distribuicao-de-rendimento': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/distribuicao-de-rendimento',
    en: '/kpis/employment/income-productivity-and-equity/income-distribution',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/moda-e-mediana-da-remuneracao': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/moda-e-mediana-da-remuneracao',
    en: '/kpis/employment/income-productivity-and-equity/mode-and-median-remuneration',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/produtividade-ajustada': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/produtividade-ajustada',
    en: '/kpis/employment/income-productivity-and-equity/adjusted-productivity',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/produtividade-aparente': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/produtividade-aparente',
    en: '/kpis/employment/income-productivity-and-equity/apparent-productivity',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/remuneracao-media-por-trabalhador': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/remuneracao-media-por-trabalhador',
    en: '/kpis/employment/income-productivity-and-equity/average-remuneration-per-worker',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/rendimento-minimo-relativo': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/rendimento-minimo-relativo',
    en: '/kpis/employment/income-productivity-and-equity/relative-minimum-income',
  },
  '/kpis/emprego/rendimentos-produtividade-e-equidade/valor-acrescentado-bruto': {
    pt: '/kpis/emprego/rendimentos-produtividade-e-equidade/valor-acrescentado-bruto',
    en: '/kpis/employment/income-productivity-and-equity/gross-value-added',
  },
  '/kpis/mercados-e-oferta-turistica/market-share': {
    pt: '/kpis/mercados-e-oferta-turistica/market-share',
    en: '/kpis/markets-and-tourist-offer/market-share',
  },
  '/kpis/mercados-e-oferta-turistica/ocupacao': {
    pt: '/kpis/mercados-e-oferta-turistica/ocupacao',
    en: '/kpis/markets-and-tourist-offer/occupancy',
  },
  '/kpis/mercados-e-oferta-turistica/ocupacao/estada-media-nos-alojamentos-turisticos': {
    pt: '/kpis/mercados-e-oferta-turistica/ocupacao/estada-media-nos-alojamentos-turisticos',
    en: '/kpis/markets-and-tourist-offer/occupancy/average-stay-in-tourist-accomodation',
  },
  '/kpis/mercados-e-oferta-turistica/ocupacao/taxa-liquida-de-ocupacao-por-cama': {
    pt: '/kpis/mercados-e-oferta-turistica/ocupacao/taxa-liquida-de-ocupacao-por-cama',
    en: '/kpis/markets-and-tourist-offer/occupancy/net-occupancy-rate-per-bed',
  },
  '/kpis/mercados-e-oferta-turistica/permanencias': {
    pt: '/kpis/mercados-e-oferta-turistica/permanencias',
    en: '/kpis/markets-and-tourist-offer/stays',
  },
  '/kpis/mercados-e-oferta-turistica/permanencias/dormidas-nos-alojamentos-turisticos': {
    pt: '/kpis/mercados-e-oferta-turistica/permanencias/dormidas-nos-alojamentos-turisticos',
    en: '/kpis/markets-and-tourist-offer/stays/overnight-stays-in-tourist-accommodation',
  },
  '/kpis/mercados-e-oferta-turistica/permanencias/hospedes-nos-alojamentos-turisticos': {
    pt: '/kpis/mercados-e-oferta-turistica/permanencias/hospedes-nos-alojamentos-turisticos',
    en: '/kpis/markets-and-tourist-offer/stays/guests-in-tourist-accommodations',
  },
  '/kpis/mercados-e-oferta-turistica/permanencias/taxa-de-sazonalidade': {
    pt: '/kpis/mercados-e-oferta-turistica/permanencias/taxa-de-sazonalidade',
    en: '/kpis/markets-and-tourist-offer/stays/seasonality-rate',
  },
  '/kpis/mercados-e-oferta-turistica/receitas': {
    pt: '/kpis/mercados-e-oferta-turistica/receitas',
    en: '/kpis/markets-and-tourist-offer/revenues',
  },
  '/kpis/mercados-e-oferta-turistica/receitas/proveitos-de-aposento': {
    pt: '/kpis/mercados-e-oferta-turistica/receitas/proveitos-de-aposento',
    en: '/kpis/markets-and-tourist-offer/revenues/retirement-income',
  },
  '/kpis/mercados-e-oferta-turistica/receitas/proveitos-totais': {
    pt: '/kpis/mercados-e-oferta-turistica/receitas/proveitos-totais',
    en: '/kpis/markets-and-tourist-offer/revenues/total-income',
  },
  '/kpis/mercados-e-oferta-turistica/receitas/rendimento-medio-por-quarto-disponivel': {
    pt: '/kpis/mercados-e-oferta-turistica/receitas/rendimento-medio-por-quarto-disponivel',
    en: '/kpis/markets-and-tourist-offer/revenues/average-yield-per-occupied-room',
  },
  '/kpis/mercados-e-oferta-turistica/receitas/rendimento-medio-por-quarto-ocupado': {
    pt: '/kpis/mercados-e-oferta-turistica/receitas/rendimento-medio-por-quarto-ocupado',
    en: '/kpis/markets-and-tourist-offer/revenues/average-yield-per-available-room',
  },
  '/kpis/expetativas-e-satisfacao/destinos': {
    pt: '/kpis/expetativas-e-satisfacao/destinos',
    en: '/kpis/expectations-and-satisfaction/destinations',
  },
} satisfies Pathnames<typeof locales>;

// Use the default: `always`
export const localePrefix = 'as-needed';

export const localeDetection = false;

export type AppPathnames = keyof typeof pathnames;

export enum EnumCrumbType {
  Data = 1,
  Blog = 2,
  News = 3,
  Highlights = 4,
  Article = 5,
  Category = 6,
  Indicator = 7,
}

export type TCrumb = {
  type: EnumCrumbType;
  slug: {
    pt: string;
    en: string;
  };
  indicatorQuery?: string;
  translationIds?: number[];
};

export const crumbList: TCrumb[] = [
  {
    type: EnumCrumbType.Data,
    slug: {
      pt: 'kpis',
      en: 'kpis',
    },
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'atividade-turistica', en: 'tourist-activity' },
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'consumos', en: 'consumption' },
    indicatorQuery: '/Data/AtividadeTuristica/Consumos',
    translationIds: [59],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'consumo-medio-de-agua-por-habitante', en: 'average-water-consumption-per-inhabitant' },
    indicatorQuery: '/Data/AtividadeTuristica/Consumos/ConsumoMedioDeAguaPorHabitante',
    translationIds: [59, 57],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'consumo-medio-de-energia-por-habitante', en: 'average-energy-consumption-per-inhabitant' },
    indicatorQuery: '/Data/AtividadeTuristica/Consumos/ConsumoMedioDeEnergiaPorHabitante',
    translationIds: [59, 58],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'consumo-total-estimado-de-agua', en: 'estimated-total-water-consumption' },
    indicatorQuery: '/Data/AtividadeTuristica/Consumos/ConsumoTotalEstimadoDeAguaDaPopulacaoFlutuante',
    translationIds: [59, 95],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'consumo-total-estimado-de-energia', en: 'estimated-total-energy-consumption' },
    indicatorQuery: '/Data/AtividadeTuristica/Consumos/ConsumoTotalEstimadoDeEnergiaElectricaDaPopulacaoFlutuante',
    translationIds: [59, 94],
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'empresas-e-negocios', en: 'companies-and-businesses' },
    indicatorQuery: '/Data/AtividadeTuristica/EmpresasENegocios',
    translationIds: [68],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'empresas-em-turismo-e-hospitalidade', en: 'companies-in-tourism-and-hospitality' },
    indicatorQuery: '/Data/AtividadeTuristica/EmpresasENegocios/Empresas',
    translationIds: [68, 70],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: {
      pt: 'empresas-recem-constituidas-em-turismo-e-hospitalidade',
      en: 'newly-established-companies-in-tourism-and-hospitality',
    },
    indicatorQuery: '/Data/AtividadeTuristica/EmpresasENegocios/EmpresasRecemConstituidas',
    translationIds: [68, 71],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'estabelecimentos-em-turismo-e-hospitalidade', en: 'establishments-in-tourism-and-hospitality' },
    indicatorQuery: '/Data/AtividadeTuristica/EmpresasENegocios/Estabelecimentos',
    translationIds: [68, 69],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: {
      pt: 'contribuicao-de-turismo-e-hospitalidade-para-o-volume-de-negocios-total',
      en: 'contribution-of-tourism-and-hospitality-to-total-business-volume',
    },
    indicatorQuery: '/Data/AtividadeTuristica/EmpresasENegocios/PercentagemVolumeNegocios',
    translationIds: [68, 72],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'volume-de-negocios-em-turismo-e-hospitalidade', en: 'business-volume-in-tourism-and-hospitality' },
    indicatorQuery: '/Data/AtividadeTuristica/EmpresasENegocios/VolumeNegocios',
    translationIds: [68, 73],
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'emprego', en: 'employment' },
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'educacao-formacao-e-carreiras', en: 'education-training-and-careers' },
    indicatorQuery: '/Data/Emprego/EmpregoEducacaoFormacaoECarreiras',
    translationIds: [19],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'diplomados-em-turismo-e-hotelaria', en: 'graduates-in-tourism-and-hospitality' },
    indicatorQuery: '/Data/Emprego/EducacaoFormacaoECarreiras/DiplomadosEmTurismoEHotelaria',
    translationIds: [19, 17],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'inscritos-em-turismo-e-hotelaria', en: 'registered-in-tourism-and-hospitality' },
    indicatorQuery: '/Data/Emprego/EducacaoFormacaoECarreiras/InscritosEmTurismoEHotelaria',
    translationIds: [19, 18],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'progressao-na-carreira', en: 'career-development' },
    indicatorQuery: '/Data/Emprego/EducacaoFormacaoECarreiras/ProgressaoNaCarreira',
    translationIds: [19, 89],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'escolaridade', en: 'education' },
    indicatorQuery: '/Data/Emprego/EducacaoFormacaoECarreiras/Qualificacao',
    translationIds: [19, 12],
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'emprego-e-sociedade', en: 'employment-and-society' },
    indicatorQuery: '/Data/Emprego/EmpregoESociedade',
    translationIds: [21],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'baixas-na-jornada-de-trabalho', en: 'absences-in-the-workday' },
    indicatorQuery: '/Data/Emprego/EmpregoESociedade/BaixasNaJornadaDeTrabalho',
    translationIds: [21, 26],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'funcionarios-de-baixa', en: 'employees-on-sick-leave' },
    indicatorQuery: '/Data/Emprego/EmpregoESociedade/FuncionariosDeBaixa',
    translationIds: [21, 22],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'funcionarios-em-licenca', en: 'employees-on-leave' },
    indicatorQuery: '/Data/Emprego/EmpregoESociedade/FuncionariosEmLicenca',
    translationIds: [21, 27],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'horas-de-trabalho', en: 'work-hours' },
    indicatorQuery: '/Data/Emprego/EmpregoESociedade/HorasDeTrabalho',
    translationIds: [21, 23],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'antiguidade-do-vinculo-laboral', en: 'age-of-employment-relationship' },
    indicatorQuery: '/Data/Emprego/EmpregoESociedade/AntiguidadeDoVinculoLaboral',
    translationIds: [21, 83],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'estabilidade', en: 'stability' },
    indicatorQuery: '/Data/Emprego/EmpregoESociedade/Estabilidade',
    translationIds: [21, 14],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'dependencia', en: 'dependency' },
    indicatorQuery: '/Data/Emprego/EmpregoESociedade/Dependencia',
    translationIds: [21, 13],
  },
  {
    type: EnumCrumbType.Category,
    slug: {
      pt: 'emprego-e-sociedade-em-turismo-e-hospitalidade',
      en: 'employment-and-society-in-tourism-and-hospitality',
    },
    indicatorQuery: '/Data/Emprego/EmpregoESociedade/TurismoEHotelaria',
    translationIds: [85],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: {
      pt: 'antiguidade-do-vinculo-laboral-em-turismo-e-hospitalidade',
      en: 'age-of-employment-relationship-in-tourism-and-hospitality',
    },
    indicatorQuery: '/Data/Emprego/EmpregoESociedade/TurismoEHotelaria/AntiguidadeDoVinculoLaboral',
    translationIds: [85, 87],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: {
      pt: 'regime-de-duracao-do-trabalho-em-turismo-e-hospitalidade',
      en: 'working-duration-regime-in-tourism-and-hospitality',
    },
    indicatorQuery: '/Data/Emprego/EmpregoESociedade/TurismoEHotelaria/RegimeDeDuracaoDoTrabalho',
    translationIds: [85, 88],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'estabilidade-em-turismo-e-hospitalidade', en: 'stability-in-tourism-and-hospitality' },
    indicatorQuery: '/Data/Emprego/EmpregoESociedade/TurismoEHotelaria/Estabilidade',
    translationIds: [85, 93],
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'mercado-laboral', en: 'labour-market' },
    indicatorQuery: '/Data/Emprego/MercadoLaboral',
    translationIds: [15],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'emprego-jovem', en: 'youth-employment' },
    indicatorQuery: '/Data/Emprego/MercadoLaboral/EmpregoJovem',
    translationIds: [15, 9],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'envelhecimento', en: 'ageing' },
    indicatorQuery: '/Data/Emprego/MercadoLaboral/Envelhecimento',
    translationIds: [15, 11],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'inclusao', en: 'inclusion' },
    indicatorQuery: '/Data/Emprego/MercadoLaboral/Inclusao',
    translationIds: [15, 10],
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'mercado-laboral-em-turismo-e-hospitalidade', en: 'labor-market-in-tourism-and-hospitality' },
    indicatorQuery: '/Data/Emprego/MercadoLaboral/TurismoEHotelaria',
    translationIds: [74],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'dependencia-em-turismo-e-hospitalidade', en: 'dependency-in-tourism-and-hospitality' },
    indicatorQuery: '/Data/Emprego/MercadoLaboral/TurismoEHotelaria/Dependencia',
    translationIds: [74, 75],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'escolaridade-em-turismo-e-hospitalidade', en: 'education-in-tourism-and-hospitality' },
    indicatorQuery: '/Data/Emprego/MercadoLaboral/TurismoEHotelaria/Qualificacao',
    translationIds: [74, 76],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'inclusao-em-turismo-e-hospitalidade', en: 'inclusion-in-tourism-and-hospitality' },
    indicatorQuery: '/Data/Emprego/MercadoLaboral/TurismoEHotelaria/Inclusao',
    translationIds: [74, 78],
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'rendimentos-em-turismo-e-hospitalidade', en: 'income-in-tourism-and-hospitality' },
    indicatorQuery: '/Data/Emprego/Rendimentos',
    translationIds: [79],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: {
      pt: 'ganho-medio-por-trabalhador-em-turismo-e-hospitalidade',
      en: 'average-earnings-per-worker-in-tourism-and-hospitality',
    },
    indicatorQuery: '/Data/Emprego/Rendimentos/GanhosMedios',
    translationIds: [79, 80],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: {
      pt: 'remuneracao-media-por-trabalhador-em-turismo-e-hospitalidade',
      en: 'average-remuneration-per-worker-in-tourism-and-hospitality',
    },
    indicatorQuery: '/Data/Emprego/Rendimentos/RemuneracaoMedia',
    translationIds: [79, 81],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: {
      pt: 'remuneracao-media-ponderada-por-trabalhador-em-turismo-e-hospitalidade',
      en: 'weighted-average-remuneration-per-worker-in-tourism-and-hospitality',
    },
    indicatorQuery: '/Data/Emprego/Rendimentos/RemuneracaoMediaPonderada',
    translationIds: [79, 96],
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'rendimentos-produtividade-e-equidade', en: 'income-productivity-and-equity' },
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'rendimentos', en: 'income' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/Remuneracoes',
    translationIds: [43], // Excepção. em vez de pageTitle será: 'pageTitleRemuneracoes', 'pageTitleProdutividade', 'pageTitleEquidade'
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'produtividade', en: 'productivity' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/Produtividade',
    translationIds: [43], // Excepção. em vez de pageTitle será: 'pageTitleRemuneracoes', 'pageTitleProdutividade', 'pageTitleEquidade'
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'equidade', en: 'equity' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/Eficiencia',
    translationIds: [43], // Excepção. em vez de pageTitle será: 'pageTitleRemuneracoes', 'pageTitleProdutividade', 'pageTitleEquidade'
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'custo-do-trabalho-por-hora', en: 'cost-of-labor-per-hour' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/CustoDoTrabalhoPorHora',
    translationIds: [43, 40],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'desigualdade-salarial-hm', en: 'wage-inequality-mw' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/DesigualdadeSalarialHM',
    translationIds: [43, 28],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: {
      pt: 'desigualdades-na-distribuicao-de-rendimentos-s80-s20',
      en: 'inequalities-in-income-distribution-s80-s20',
    },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/DNDDeRRacioS80S20',
    translationIds: [43, 37],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: {
      pt: 'desigualdades-na-distribuicao-de-rendimentos-s90-s10',
      en: 'inequalities-in-income-distribution-s90-s10',
    },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/DNDDeRRacioS90S10',
    translationIds: [43, 38],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'desvio-padrao-da-remuneracao', en: 'standard-deviation-of-remuneration' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/DesvioPadraoDaRemuneracao',
    translationIds: [43, 41],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'distribuicao-de-rendimento', en: 'income-distribution' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/DistribuicaoDeRendimento',
    translationIds: [43, 29],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'moda-e-mediana-da-remuneracao', en: 'mode-and-median-remuneration' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/ModaEMedianaDaRemuneracao',
    translationIds: [43, 36],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'produtividade-ajustada', en: 'adjusted-productivity' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/ProdutividadeAjustada',
    translationIds: [43, 42],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'produtividade-aparente', en: 'apparent-productivity' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/ProdutividadeAparente',
    translationIds: [43, 31],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'remuneracao-media-por-trabalhador', en: 'average-remuneration-per-worker' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/RemuneracaoMediaPorTrabalhador',
    translationIds: [43, 35],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'rendimento-minimo-relativo', en: 'relative-minimum-income' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/RendimentoMinimoRelativo',
    translationIds: [43, 30],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'valor-acrescentado-bruto', en: 'gross-value-added' },
    indicatorQuery: '/Data/Emprego/RemuneracoesProdutividadeEEficiencia/ValorAcrescentadoBruto',
    translationIds: [43, 39],
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'mercados-e-oferta-turistica', en: 'markets-and-tourist-offer' },
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'market-share', en: 'market-share' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/MarketShare',
    translationIds: [56],
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'ocupacao', en: 'occupancy' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/Ocupacao',
    translationIds: [51],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'estada-media-nos-alojamentos-turisticos', en: 'average-stay-in-tourist-accomodation' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/Ocupacao/EstadaMediaNosAlojamentosTuristicos',
    translationIds: [51, 50],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'taxa-liquida-de-ocupacao-por-cama', en: 'net-occupancy-rate-per-bed' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/Ocupacao/TaxaLiquidaDeOcupacaoPorCama',
    translationIds: [51, 49],
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'permanencias', en: 'stays' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/Permanencias',
    translationIds: [55],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'dormidas-nos-alojamentos-turisticos', en: 'overnight-stays-in-tourist-accommodation' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/Permanencias/DormidasNosAlojamentosTuristicos',
    translationIds: [55, 52],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'hospedes-nos-alojamentos-turisticos', en: 'guests-in-tourist-accommodations' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/Permanencias/HospedesNosAlojamentosTuristicos',
    translationIds: [55, 53],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'taxa-de-sazonalidade', en: 'seasonality-rate' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/Permanencias/TaxaDeSazonalidade',
    translationIds: [55, 54],
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'receitas', en: 'revenues' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/Receitas',
    translationIds: [48],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'proveitos-de-aposento', en: 'retirement-income' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/Receitas/ProveitosDeAposento',
    translationIds: [48, 47],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'proveitos-totais', en: 'total-income' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/Receitas/ProveitosTotais',
    translationIds: [48, 46],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'rendimento-medio-por-quarto-disponivel', en: 'average-yield-per-occupied-room' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/Receitas/RendimentoMedioPorQuartoDisponivel',
    translationIds: [48, 45],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'rendimento-medio-por-quarto-ocupado', en: 'average-yield-per-available-room' },
    indicatorQuery: '/Data/MercadosEOfertaTuristica/Receitas/RendimentoMedioPorQuartoOcupado',
    translationIds: [48, 44],
  },
  {
    type: EnumCrumbType.Category,
    slug: { pt: 'expetativas-e-satisfacao', en: 'expectations-and-satisfaction' },
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'destinos', en: 'destinations' },
    indicatorQuery: '/Data/ExpetativasESatisfacao/Destinos',
    translationIds: [90],
  },
  {
    type: EnumCrumbType.Indicator,
    slug: { pt: 'company-kpis', en: 'company-kpis' },
    indicatorQuery: '/barturs/Organizations/Benchmarking',
    translationIds: [],
  },
];

export interface TConfigList {
  path: AppPathnames;
  config: IConfig[];
}

export const configList: TConfigList[] = [
  {
    path: '/kpis/atividade-turistica/consumos',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Value: 0,
            TitleValue: '',
            Variation: 0,
            TitleVariation: '',
            Link: '/kpis/atividade-turistica/consumos/consumo-medio-de-agua-por-habitante',
          },
          classname: 'flex place-content-center col-span-full lg:col-span-3 w-full rounded-lg min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            Value: 0,
            TitleValue: '',
            Variation: 0,
            TitleVariation: '',
            Link: '/kpis/atividade-turistica/consumos/consumo-medio-de-energia-por-habitante',
          },
          classname: 'flex place-content-center col-span-full lg:col-span-3 w-full rounded-lg',
        },
      },
      {
        type: 'graph-v3',
        index: 1,
        graphv3: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            Value: 0,
            TitleValue: '',
            Variation: 0,
            TitleVariation: '',
            Link: '/kpis/atividade-turistica/consumos/consumo-total-estimado-de-agua',
          },
          classname: 'flex place-content-center col-span-full lg:col-span-3 w-full rounded-lg min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 4,
        cardv0: {
          data: {
            Value: 0,
            TitleValue: '',
            Variation: 0,
            TitleVariation: '',
            Link: '/kpis/atividade-turistica/consumos/consumo-total-estimado-de-energia',
          },
          classname: 'flex place-content-center col-span-full lg:col-span-3 w-full rounded-lg',
        },
      },
      {
        type: 'graph-v3',
        index: 2,
        graphv3: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/atividade-turistica/consumos/consumo-medio-de-agua-por-habitante',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Value: 0,
            TitleValue: '',
            Variation: 0,
            TitleVariation: '',
          },
          classname: 'flex col-span-full lg:col-span-3 min-h-[350px] w-full rounded-lg shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/atividade-turistica/consumos/consumo-medio-de-energia-por-habitante',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-full lg:col-span-3 min-h-[350px] w-full rounded-lg shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/atividade-turistica/consumos/consumo-total-estimado-de-agua',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-full lg:col-span-3 min-h-[350px] w-full rounded-lg shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/atividade-turistica/consumos/consumo-total-estimado-de-energia',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-full lg:col-span-3 min-h-[350px] w-full rounded-lg shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/atividade-turistica/empresas-e-negocios',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/atividade-turistica/empresas-e-negocios/estabelecimentos-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-full lg:col-span-3 min-h-[350px] w-full rounded-lg shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            Link: '/kpis/atividade-turistica/empresas-e-negocios/empresas-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-full lg:col-span-3 min-h-[350px] w-full rounded-lg shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            Link: '/kpis/atividade-turistica/empresas-e-negocios/empresas-recem-constituidas-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-full lg:col-span-3 min-h-[350px] w-full rounded-lg shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 4,
        cardv0: {
          data: {
            Link: '/kpis/atividade-turistica/empresas-e-negocios/volume-de-negocios-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-full lg:col-span-3 min-h-[350px] w-full rounded-lg shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 5,
        cardv0: {
          data: {
            Link: '/kpis/atividade-turistica/empresas-e-negocios/contribuicao-de-turismo-e-hospitalidade-para-o-volume-de-negocios-total',
          },
          classname: 'flex col-span-full lg:col-span-3 min-h-[350px] w-full rounded-lg shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {
            y: {
              beginAtZero: false,
            },
            legend: {
              display: true,
            },
          },
        },
      },
      {
        type: 'graph-v3',
        index: 2,
        graphv3: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {
            y: {
              beginAtZero: false,
            },
            legend: {
              display: true,
            },
          },
        },
      },
    ],
  },
  {
    path: '/kpis/atividade-turistica/empresas-e-negocios/contribuicao-de-turismo-e-hospitalidade-para-o-volume-de-negocios-total',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-full lg:col-span-3 min-h-[350px] w-full rounded-lg shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {},
        },
      },
    ],
  },
  {
    path: '/kpis/atividade-turistica/empresas-e-negocios/empresas-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-full lg:col-span-3 min-h-[350px] w-full rounded-lg shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/atividade-turistica/empresas-e-negocios/empresas-recem-constituidas-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-full lg:col-span-3 min-h-[350px] w-full rounded-lg shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/atividade-turistica/empresas-e-negocios/estabelecimentos-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/atividade-turistica/empresas-e-negocios/volume-de-negocios-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/educacao-formacao-e-carreiras',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/emprego/educacao-formacao-e-carreiras/diplomados-em-turismo-e-hotelaria',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            Link: '/kpis/emprego/educacao-formacao-e-carreiras/inscritos-em-turismo-e-hotelaria',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            Link: '/kpis/emprego/educacao-formacao-e-carreiras/escolaridade',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 4,
        cardv0: {
          data: {
            Link: '/kpis/emprego/educacao-formacao-e-carreiras/progressao-na-carreira',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v3',
        index: 1,
        graphv3: {
          classname:
            'col-span-1 lg:col-span-12 flex w-full min-h-[450px] justify-center overflow-auto border border-slate-400 shadow-md',
          options: {
            legend: {
              display: true,
            },
          },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/educacao-formacao-e-carreiras/diplomados-em-turismo-e-hotelaria',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 4,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 6,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 30 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/educacao-formacao-e-carreiras/escolaridade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 6,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v5',
        index: 9,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v5',
        index: 11,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 13,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/educacao-formacao-e-carreiras/inscritos-em-turismo-e-hotelaria',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 4,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 5,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/educacao-formacao-e-carreiras/progressao-na-carreira',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 6,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v5',
        index: 9,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v5',
        index: 11,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 13,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/emprego-e-sociedade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/emprego/emprego-e-sociedade/funcionarios-de-baixa',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            Link: '/kpis/emprego/emprego-e-sociedade/horas-de-trabalho',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            Link: '/kpis/emprego/emprego-e-sociedade/horas-de-trabalho',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 4,
        cardv0: {
          data: {
            Link: '/kpis/emprego/emprego-e-sociedade/baixas-na-jornada-de-trabalho',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 5,
        cardv0: {
          data: {
            Link: '/kpis/emprego/emprego-e-sociedade/funcionarios-em-licenca',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 6,
        cardv0: {
          data: {
            Link: '/kpis/emprego/emprego-e-sociedade/dependencia',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 7,
        cardv0: {
          data: {
            Link: '/kpis/emprego/emprego-e-sociedade/estabilidade',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 8,
        cardv0: {
          data: {
            Link: '/kpis/emprego/emprego-e-sociedade/antiguidade-do-vinculo-laboral',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 9,
        cardv0: {
          data: {
            Link: '/kpis/emprego/emprego-e-sociedade/tipo-de-contrato',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v3',
        index: 1,
        graphv3: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { legend: { display: true } },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/emprego-e-sociedade/antiguidade-do-vinculo-laboral',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { y: { beginAtZero: true } },
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 6,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v5',
        index: 9,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v5',
        index: 11,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 13,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/emprego-e-sociedade/baixas-na-jornada-de-trabalho',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 70 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/emprego-e-sociedade/dependencia',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/emprego-e-sociedade/estabilidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 6,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v5',
        index: 9,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v5',
        index: 11,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 13,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/emprego-e-sociedade/funcionarios-de-baixa',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/emprego-e-sociedade/funcionarios-em-licenca',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 3,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/emprego-e-sociedade/horas-de-trabalho',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md rounded-lg',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barPercentage: 0.4 },
        },
      },
      {
        type: 'graph-v0',
        index: 3,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade/antiguidade-do-vinculo-laboral-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            Link: '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade/regime-de-duracao-do-trabalho-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            Link: '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade/estabilidade-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { legend: { display: true } },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade/antiguidade-do-vinculo-laboral-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 6,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v5',
        index: 9,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 11,
        graphv0: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade/estabilidade-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 6,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v5',
        index: 9,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 11,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 13,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md relative rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/emprego-e-sociedade-em-turismo-e-hospitalidade/regime-de-duracao-do-trabalho-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 6,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v5',
        index: 9,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 11,
        graphv0: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/mercado-laboral',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/emprego/mercado-laboral/envelhecimento',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            Link: '/kpis/emprego/mercado-laboral/inclusao',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            Link: '/kpis/emprego/mercado-laboral/emprego-jovem',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true } },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/mercado-laboral/emprego-jovem',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/mercado-laboral/envelhecimento',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/mercado-laboral/inclusao',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade/escolaridade-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            Link: '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade/inclusao-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            Link: '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade/dependencia-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true } },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade/dependencia-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 6,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v5',
        index: 9,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 11,
        graphv0: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade/escolaridade-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v0',
        index: 3,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 5,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 6,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v5',
        index: 9,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v5',
        index: 11,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 13,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/mercado-laboral-em-turismo-e-hospitalidade/inclusao-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 6,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v5',
        index: 9,
        graphv5: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v5',
        index: 11,
        graphv5: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 13,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade/ganho-medio-por-trabalhador-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            Link: '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade/remuneracao-media-por-trabalhador-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 4,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 5,
        cardv0: {
          data: {
            Link: '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade/remuneracao-media-ponderada-por-trabalhador-em-turismo-e-hospitalidade',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true } },
        },
      },
      {
        type: 'card-v0',
        index: 5,
        cardv0: {
          data: {},
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 6,
        cardv0: {
          data: {},
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v7',
        index: 2,
        graphv7: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: false } },
        },
      },
      {
        type: 'card-v0',
        index: 7,
        cardv0: {
          data: {},
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 8,
        cardv0: {
          data: {},
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v7',
        index: 3,
        graphv7: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: false } },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade/ganho-medio-por-trabalhador-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 6,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 9,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 11,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 13,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade/remuneracao-media-por-trabalhador-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 6,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 9,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 11,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 13,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-em-turismo-e-hospitalidade/remuneracao-media-ponderada-por-trabalhador-em-turismo-e-hospitalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 6,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 8,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 9,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 10,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 11,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 12,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 13,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/rendimentos',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/distribuicao-de-rendimento',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            TitleValue: '',
            TitleVariation: '',
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/rendimento-minimo-relativo',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            TitleValue: '',
            TitleVariation: '',
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/desvio-padrao-da-remuneracao',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 4,
        cardv0: {
          data: {
            TitleValue: '',
            TitleVariation: '',
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/remuneracao-media-por-trabalhador',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 5,
        cardv0: {
          data: {
            TitleValue: '',
            TitleVariation: '',
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/moda-e-mediana-da-remuneracao',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 6,
        cardv0: {
          data: {
            TitleValue: '',
            TitleVariation: '',
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/distribuicao-de-rendimento',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v3',
        index: 1,
        graphv3: {
          classname:
            'col-span-full flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true } },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/equidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/desigualdades-na-distribuicao-de-rendimentos-s90-s10',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            TitleValue: '',
            TitleVariation: '',
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/desigualdades-na-distribuicao-de-rendimentos-s80-s20',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            TitleValue: '',
            TitleVariation: '',
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/desigualdade-salarial-hm',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v3',
        index: 1,
        graphv3: {
          classname:
            'col-span-full flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true } },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/produtividade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/custo-do-trabalho-por-hora',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            TitleValue: '',
            TitleVariation: '',
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/produtividade-aparente',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            TitleValue: '',
            TitleVariation: '',
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/produtividade-ajustada',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 4,
        cardv0: {
          data: {
            TitleValue: '',
            TitleVariation: '',
            Link: '/kpis/emprego/rendimentos-produtividade-e-equidade/valor-acrescentado-bruto',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v3',
        index: 1,
        graphv3: {
          classname:
            'col-span-full flex w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true } },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/custo-do-trabalho-por-hora',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 2,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/desigualdade-salarial-hm',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 4,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 5,
        graphv2: {
          classname:
            'col-span-full flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/desigualdades-na-distribuicao-de-rendimentos-s80-s20',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/desigualdades-na-distribuicao-de-rendimentos-s90-s10',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/desvio-padrao-da-remuneracao',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/distribuicao-de-rendimento',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true } },
        },
      },
      {
        type: 'graph-v1',
        index: 2,
        graphv1: {
          classname:
            'col-span-full flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true } },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/moda-e-mediana-da-remuneracao',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true } },
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/produtividade-ajustada',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 3,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/produtividade-aparente',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/remuneracao-media-por-trabalhador',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 2,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/rendimento-minimo-relativo',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v1',
        index: 2,
        graphv1: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/emprego/rendimentos-produtividade-e-equidade/valor-acrescentado-bruto',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { beginAtZero: false } },
        },
      },
    ],
  },
  {
    path: '/kpis/expetativas-e-satisfacao/destinos',
    config: [
      {
        type: 'graph-v2',
        index: 1,
        graphv2: {
          classname:
            'col-span-1 lg:col-span-6 flex w-full h-[400px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-1 lg:col-span-6 flex w-full h-[400px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v1',
        index: 3,
        graphv1: {
          classname:
            'col-span-1 lg:col-span-full flex w-full h-[600px] md:h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true }, points: true },
        },
      },
      {
        type: 'graph-v6',
        index: 4,
        graphv6: {
          classname:
            'col-span-1 lg:col-span-full flex w-full h-[600px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/market-share',
    config: [
      {
        type: 'graph-v4',
        index: 1,
        graphv4: {
          classname:
            'col-span-1 lg:col-span-full w-full h-[500px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/ocupacao',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/mercados-e-oferta-turistica/ocupacao/estada-media-nos-alojamentos-turisticos',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            Link: '/kpis/mercados-e-oferta-turistica/ocupacao/taxa-liquida-de-ocupacao-por-cama',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v3',
        index: 1,
        graphv3: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true }, y: { beginAtZero: true } },
        },
      },
      {
        type: 'graph-v3',
        index: 2,
        graphv3: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true }, y: { beginAtZero: true } },
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/ocupacao/estada-media-nos-alojamentos-turisticos',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v1',
        index: 2,
        graphv1: {
          classname:
            'col-span-full lg:col-span-7 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true }, y: { beginAtZero: true } },
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-5 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 4,
        graphv0: {
          classname:
            'col-span-full lg:col-span-8 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 5,
        graphv2: {
          classname:
            'col-span-full lg:col-span-4 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 6,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/ocupacao/taxa-liquida-de-ocupacao-por-cama',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v1',
        index: 2,
        graphv1: {
          classname:
            'col-span-full lg:col-span-7 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true } },
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-5 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 4,
        graphv0: {
          classname:
            'col-span-full flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/permanencias',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/mercados-e-oferta-turistica/permanencias/dormidas-nos-alojamentos-turisticos',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            Link: '/kpis/mercados-e-oferta-turistica/permanencias/hospedes-nos-alojamentos-turisticos',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            Link: '/kpis/mercados-e-oferta-turistica/permanencias/taxa-de-sazonalidade',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v3',
        index: 1,
        graphv3: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true } },
        },
      },
      {
        type: 'graph-v1',
        index: 2,
        graphv1: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true }, y: { beginAtZero: true } },
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/permanencias/dormidas-nos-alojamentos-turisticos',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
      {
        type: 'graph-v1',
        index: 2,
        graphv1: {
          classname:
            'col-span-full lg:col-span-7 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { legend: { display: true } },
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-5 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 4,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 6,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {},
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/permanencias/hospedes-nos-alojamentos-turisticos',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v1',
        index: 2,
        graphv1: {
          classname:
            'col-span-full lg:col-span-7 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-5 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 4,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 6,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 7,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/permanencias/taxa-de-sazonalidade',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 2,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 4,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 5,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/receitas',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          data: {
            Link: '/kpis/mercados-e-oferta-turistica/receitas/rendimento-medio-por-quarto-ocupado',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 2,
        cardv0: {
          data: {
            Link: '/kpis/mercados-e-oferta-turistica/receitas/rendimento-medio-por-quarto-disponivel',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 3,
        cardv0: {
          data: {
            Link: '/kpis/mercados-e-oferta-turistica/receitas/proveitos-totais',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'card-v0',
        index: 4,
        cardv0: {
          data: {
            Link: '/kpis/mercados-e-oferta-turistica/receitas/proveitos-de-aposento',
          },
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v3',
        index: 1,
        graphv3: {
          classname:
            'col-span-full flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: {
            y: {
              min: 0,
            },
            legend: {
              display: true,
            },
          },
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/receitas/proveitos-de-aposento',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 3,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/receitas/proveitos-totais',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 3,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/receitas/rendimento-medio-por-quarto-disponivel',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 3,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/kpis/mercados-e-oferta-turistica/receitas/rendimento-medio-por-quarto-ocupado',
    config: [
      {
        type: 'card-v0',
        index: 1,
        cardv0: {
          classname: 'flex col-span-1 sm:col-auto lg:col-span-3 w-full shadow-md min-h-[300px]',
        },
      },
      {
        type: 'graph-v1',
        index: 1,
        graphv1: {
          classname:
            'col-span-full lg:col-span-9 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v2',
        index: 2,
        graphv2: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v0',
        index: 3,
        graphv0: {
          classname:
            'col-span-full lg:col-span-6 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
    ],
  },
  {
    path: '/painel/company-kpis',
    config: [
      {
        type: 'graph-v3',
        index: 1,
        graphv3: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[350px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v1',
        index: 2,
        graphv1: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
        },
      },
      {
        type: 'graph-v2',
        index: 3,
        graphv2: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v2',
        index: 4,
        graphv2: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v0',
        index: 5,
        graphv0: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { barThickness: 50 },
        },
      },
      {
        type: 'graph-v1',
        index: 6,
        graphv1: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v1',
        index: 7,
        graphv1: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v3',
        index: 8,
        graphv3: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v1',
        index: 9,
        graphv1: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v1',
        index: 10,
        graphv1: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v3',
        index: 11,
        graphv3: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v1',
        index: 12,
        graphv1: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v1',
        index: 13,
        graphv1: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v1',
        index: 14,
        graphv1: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v1',
        index: 15,
        graphv1: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
      {
        type: 'graph-v1',
        index: 16,
        graphv1: {
          classname:
            'col-span-full lg:col-span-12 flex w-full h-[450px] justify-center overflow-auto border border-slate-400 shadow-md rounded-lg',
          options: { y: { min: 0 } },
        },
      },
    ],
  },
];
